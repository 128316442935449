import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ValuesOne from "../components/values/Values_5";
import BlockFour from "../components/block/Block_9";
import BlockOne from "../components/block/Block_8";

function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="About">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="About"
          Subheader="About"
          bgimg={`${rpdata?.stock?.[5]}`}
        />
        <BlockFour
          title={'a little about us'}
          text={rpdata?.dbAbout?.[1]?.text}
          image1={rpdata?.gallery?.[6]}
          image4={rpdata?.gallery?.[2]}
          image3={rpdata?.gallery?.[50]}
          image2={rpdata?.gallery?.[7]}
          listsAbout
        />
        <ValuesOne image={rpdata?.gallery?.[10]}
          image1={rpdata?.gallery?.[10]}
          image3={rpdata?.gallery?.[80]}
          image2={rpdata?.gallery?.[100]}
        />

        <BlockOne
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image1={rpdata?.stock?.[1]}
          image={rpdata?.gallery?.[11]}
        />
      </div>
    </BaseLayout>
  );
}

export default About;
